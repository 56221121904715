import React from 'react'
import Img from 'gatsby-image'
import { css } from '@emotion/core'
import { GitHub, Email } from '../components/Social'
import Grid from '@material-ui/core/Grid'
import theme from '../../config/theme'
import SubscribeForm from './Forms/Subscribe'

const Hero = ({ avatar }) => (
  <section
    css={css`
      * {
        color: ${theme.colors.white};
      }
      width: 100%;
      background: ${theme.brand.primary};
      padding: 20px 0 30px 0;
      display: flex;
    `}
  >
    <div
      css={css`
        max-width: 624px;
        margin: auto;
      `}
    >
      <Grid
        css={css`
          align-items: center;
          @media (max-width: 599px) {
            text-align: center;
          }
        `}
        container
        spacing={24}
      >
        <Grid
          item
          xs={3}
          css={css`
            @media (min-width: 600px) {
              display: none;
            }
          `}
        />
        <Grid item xs={6} sm={3}>
          <Img
            css={css`
              border: 5px solid white;
              border-radius: 50%;
              flex: 1;
              background: white;
            `}
            fluid={avatar}
            alt="Wombat"
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <h1
            css={css`
              margin-top: 0;
            `}
          >
            Luke Mayhew
          </h1>
          <p>
            Front-end dev. Mentor to struggling developers. Pursuer of growth,
            wisdom, and a life well-lived.
          </p>
          <div
            css={css`
              margin-left: -10px;
              & > a:hover {
                border-bottom: 1px solid white;
              }
            `}
          >
            <GitHub />
            <Email />
          </div>
        </Grid>
      </Grid>
    </div>
  </section>
)

export default Hero
