import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'
import SEO from 'components/SEO'
import { css } from '@emotion/core'
import Container from 'components/Container'
import Layout from '../components/Layout'
import { fonts } from '../lib/typography'
// import Share from '../components/Share'
import config from '../../config/website'
import { bpMaxSM } from '../lib/breakpoints'

import Hero from '../components/Hero'
import theme from '../../config/theme'

export default function Post({
  data: { site, mdx, file },
  pageContext: { next, prev },
}) {
  const author = mdx.frontmatter.author || config.author
  const date = mdx.frontmatter.date
  const title = mdx.frontmatter.title
  const banner = mdx.frontmatter.banner
  const bannerCaption = mdx.frontmatter.bannerCaption

  return (
    <Layout
      site={site}
      headerColor={theme.colors.white}
      headerBg={theme.brand.primary}
      frontmatter={mdx.frontmatter}
    >
      <SEO frontmatter={mdx.frontmatter} isBlogPost />
      <Hero avatar={file.childImageSharp.fluid} />
      <article
        css={css`
          width: 100%;
          display: flex;
        `}
      >
        <Container>
          <h1
            css={css`
              text-align: center;
              margin-bottom: 20px;
            `}
          >
            {title}
          </h1>
          <div
            css={css`
              display: flex;
              justify-content: center;
              margin-bottom: 20px;
              h3,
              span {
                text-align: center;
                font-size: 15px;
                opacity: 0.6;
                font-family: ${fonts.regular}, sans-serif;
                font-weight: normal;
                margin: 0 5px;
              }
            `}
          >
            {author && <h3>{author}</h3>}
            {author && <span>—</span>}
            {date && <h3>{date}</h3>}
          </div>
          {banner && (
            <div
              css={css`
                padding: 30px;
                ${bpMaxSM} {
                  padding: 0;
                }
                figure {
                  margin-bottom: 0;
                }
                figure,
                figure > div {
                  text-align: center;
                }
                figure > div {
                  margin-bottom: 0.5rem;
                }
                figcaption {
                  font-size: 75%;
                  color: rgba(0, 0, 0, 0.68);
                }
              `}
            >
              <figure>
                <Img
                  sizes={banner.childImageSharp.fluid}
                  alt={site.siteMetadata.keywords.join(', ')}
                />
                {bannerCaption && (
                  <figcaption
                    dangerouslySetInnerHTML={{ __html: bannerCaption }}
                  />
                )}
              </figure>
            </div>
          )}
          <br />
          <div
            css={css`
              p > img:only-child {
                display: block;
                margin-left: auto;
                margin-right: auto;
              }
              figure,
              figure > p {
                text-align: center;
              }
              figure > p {
                margin-bottom: 0.5rem;
              }
              figcaption {
                font-size: 75%;
                color: rgba(0, 0, 0, 0.68);
              }
            `}
          >
            <MDXRenderer>{mdx.code.body}</MDXRenderer>
          </div>
        </Container>
        {/* <SubscribeForm /> */}
      </article>
      {/* <Container noVerticalPadding>
        <Share
          url={`${config.siteUrl}/${mdx.frontmatter.slug}/`}
          title={title}
          twitterHandle={config.twitterHandle}
        />
        <br />
        <br />
      </Container> */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      ...site
    }
    file(relativePath: { eq: "luke-avatar.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mdx(fields: { id: { eq: $id } }) {
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        banner {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        bannerCaption
        slug
        keywords
        canonical
      }
      code {
        body
      }
    }
  }
`
